import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import 'bootstrap/js/src/dropdown';
import Headroom from 'headroom.js';
import Houdini from 'houdinijs';
import MicroModal from 'micromodal';
import sidePanel from 'side-panel-menu-thing';
import SmoothScroll from 'smooth-scroll';
import stickyFooter from 'sticky-footer';
import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs } from 'swiper/core';
import Tabby from 'tabbyjs';
import tippy from 'tippy.js';
import LazyLoad from 'vanilla-lazyload';

// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
stickyFooter.init({
  selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
  selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
  callback: function () {}, // Runs after the footer is stuck
});
// -------------------------------------------------------------------
// HoudiniJS
// -------------------------------------------------------------------
var disclosure = new Houdini('[data-houdini-group]', {
  isAccordion: true,
});
// -------------------------------------------------------------------
// BiblioのAdditional Infoの開閉
// -------------------------------------------------------------------
const disclosureAllElem = document.querySelector('.js-disclosureAll');
if (disclosureAllElem === null) {
} else {
  const disclosureAll = document.querySelectorAll(
    '.p-biblio__Additional-body .c-expander-c',
  );
  const disclosureTriggerAll = document.querySelectorAll(
    '.p-biblio__Additional-body .c-expander-t .houdini-toggle',
  );

  document
    .querySelector('.js-disclosureAll')
    .addEventListener('click', function () {
      if (this.textContent == '全てひらく') {
        this.textContent = '全てとじる';
        disclosureAll.forEach((element) => {
          element.classList.add('is-expanded');
        });
        disclosureTriggerAll.forEach((element) => {
          element.setAttribute('aria-expanded', 'true');
        });
      } else {
        this.textContent = '全てひらく';
        disclosureAll.forEach((element) => {
          element.classList.remove('is-expanded');
        });
        disclosureTriggerAll.forEach((element) => {
          element.setAttribute('aria-expanded', 'false');
        });
      }
    });
}

// -------------------------------------------------------------------
// MicroModal
// -------------------------------------------------------------------
MicroModal.init({
  // onShow: modal => console.info(`${modal.id} is shown`), // [1]
  // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
  // openTrigger: 'data-custom-open', // [3]
  // closeTrigger: 'data-custom-close', // [4]
  openClass: 'is-open', // [5]
  disableScroll: true, // [6]
  disableFocus: true, // [7]
  awaitOpenAnimation: false, // [8]
  awaitCloseAnimation: false, // [9]
  // debugMode: true // [10]
});
// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
// const header = document.querySelector(".l-header");
// const headroom = new Headroom(header);
// headroom.init();
const header = document.querySelector('.l-header');
const headroom = new Headroom(header, {
  //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
  offset: 100,
  tolerance: 10,
  classes: {
    initial: 'initial',
  },
});
headroom.init();
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]', {
  header: '.l-header',
  speed: 50,
  speedAsDuration: false,
});
// scroll.animateScroll(80);
// -------------------------------------------------------------------
// Tippy.js
// -------------------------------------------------------------------
new tippy('.js-tippy', {
  theme: 'org',
  arrow: true,
  hideOnClick: true,
  arrowType: 'round', // or 'sharp' (default)
  interactive: false,
  maxWidth: '30em',
});
// -------------------------------------------------------------------
// side-panel-menu-thing
// -------------------------------------------------------------------
const offCanvasMenu = sidePanel({
  target: document.body,
  width: 400,
  duration: 200,
  preventScroll: true,
  content: document.getElementById('js-offCanvasMenu'),
});
document
  .getElementById('js-offCanvasMenuTrigger')
  .addEventListener('click', offCanvasMenu.show);
document
  .getElementById('js-offCanvasMenuHidden')
  .addEventListener('click', offCanvasMenu.hide);
// if ($('#js-offCanvasMenuTrigger').length) {
//     document.getElementById('js-offCanvasMenuTrigger').addEventListener('click', offCanvasMenu.show);
//     document.getElementById('js-offCanvasMenuHidden').addEventListener('click', offCanvasMenu.hide);
// } else {}
// options are accessible / changeable afterward
// offCanvasMenu.width
// offCanvasMenu.width = 500
// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
const tabsElem = document.querySelector('[data-tabs]');
if (tabsElem === null) {
} else {
  var tabs = new Tabby('[data-tabs]');
}

// -------------------------------------------------------------------
// LazyLoad
// -------------------------------------------------------------------
const lazyContent = new LazyLoad({
  elements_selector: 'img.lazy',
  use_native: true, // <-- there you go
});
const lazyBackground = new LazyLoad({
  elements_selector: 'iframe.lazy, video.lazy, div.lazy',
  // DON'T PASS use_native: true HERE
});

// -------------------------------------------------------------------
// Fancybox
// -------------------------------------------------------------------
Fancybox.bind("[data-fancybox='book-cover']", {
  protect: true,
  infinite: false,
  Toolbar: false,
  Image: {
    zoom: false,
    click: false,
    wheel: false,
    fit: 'contain-w',
  },
});
// -------------------------------------------------------------------
// Swiper
// -------------------------------------------------------------------
// ++++++++++++++++++++
// 書誌ページの書影エリア
// ++++++++++++++++++++
Swiper.use([Navigation, Pagination, Thumbs]);
var swiper = new Swiper('.js-bookCover', {
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: false,
  },
});
// ++++++++++++++++++++
// TOP・カバー
// ++++++++++++++++++++
var coverThumbs = new Swiper('.js-coverThumbs', {
  // centeredSlides: true,
  direction: 'vertical',
  // grabCursor: true,
  // loop: true,
  observer: true,
  spaceBetween: 12,
  slidesPerView: 3,
  freeMode: true,
  watchSlidesProgress: true,
  resizeObserver: true,
  renderExternalUpdate: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    0: {
      direction: 'horizontal',
      slidesPerView: 5,
      spaceBetween: 8,
    },
    1192: {
      direction: 'vertical',
      slidesPerView: 3,
    },
  },
});
// ++++++++++++++++++++
// TOP・カバーサムネ
// ++++++++++++++++++++
var coverBody = new Swiper('.js-coverBody', {
  direction: 'vertical',
  grabCursor: true,
  observer: true,
  loop: true,
  resizeObserver: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  thumbs: {
    swiper: coverThumbs,
  },
  breakpoints: {
    0: {
      direction: 'horizontal',
    },
    944: {
      direction: 'vertical',
    },
  },
});
